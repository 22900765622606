import SectionTitle from "../../../common/sectionTitle";
import TokenDistributionWrapper from "./tokenDistribution.style";

const TokenDistribution = () => {
  return (
    <TokenDistributionWrapper>
      <div className="container how_to_mint_container">
        <SectionTitle
          classNameName="md-pb-20"
          title="Token "
          subtitle="Funds allocation"
        />
        <div className="row">
          <div className="col-lg-6">
            <img src={"https://imgur.com/4zS9GbW.png"} alt="" />
          </div>
          <div className="col-lg-6 how_to_mint_content">
            <p>Total SMTX Supply: 1 Billion</p>
            <table>
              <tr style={{ color: 'white' }}>
                <td></td>
                <td style={{ paddingRight: 20, fontWeight: 'bold' }}>Allocation % </td>
                <td style={{ fontWeight: 'bold' }}>Details</td>
              </tr>
              <tr style={{ color: 'white', padding: 20 }}>
                <td>Team</td>
                <td>20%</td>
                <td>20% per annum vested 5 year</td>
              </tr>
              <tr style={{ color: 'white', padding: 20 }}>
                <td>Advisor</td>
                <td>5%</td>
                <td>20% per annum vested 5 year</td>
              </tr>
              <tr style={{ color: 'white', padding: 20 }}>
                <td>Presale </td>
                <td>20%</td>
                <td>Vested 6 months</td>
              </tr>
              <tr style={{ color: 'white', marginRight: 20 }}>
                <td style={{ paddingRight: 20 }}>Staking & Rewards</td>
                <td>20%</td>
                <td></td>
              </tr>
              <tr style={{ color: 'white', padding: 20 }}>
                <td>Fairlaunch</td>
                <td>20%</td>
                <td></td>
              </tr>
              <tr style={{ color: 'white', padding: 20 }}>
                <td>Marketing</td>
                <td>15%</td>
                <td></td>
              </tr>
            </table>
          </div>
        </div>
        <div style={{ marginTop: 20, color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <p>SMTX price is determine as per below:</p>
          <p style={{ textAlign: 'center' }}>Utilizing Net Asset Value Per Share (NAVPS) as the basis of token price:</p>
          <div>
            <div className="col-lg-12">
              Total Asset - Total Liabilities
            </div>
            <div style={{ height: 1, backgroundColor: 'white' }} className="col-lg-12" />
            <div className="col-lg-12">
              Number of Shares Outstanding
            </div>
          </div>
        </div>

      </div>
    </TokenDistributionWrapper>
  );
};

export default TokenDistribution;
