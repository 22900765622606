import { useModal } from "../../../../utils/ModalContext";
import Counter from "../../../../common/counter";
import Button from "../../../../common/button";
import BannerV1Wrapper from "./Banner.style";
import _ from 'lodash';
import { useEffect, useState, useContext } from "react";
import { isMetaMaskInstalled } from '../../../../config';
import { getPurchasedAmount, web3Modal, web3modalStorageKey, getXSUMONFT, getXSUMONFTArray, getSESumoNFT } from '../../../../utils/web3mint';

const Banner = () => {
  const { mintModalHandle, walletModalHandle, claimModalHandle, metamaskModalHandle, account } = useModal();
  const [remaining, setRemaining] = useState(0);
  useEffect(() => {
    const calculateRemainingItems = async () => {
      let totalPurchasedAmount = await getPurchasedAmount();
      setRemaining(totalPurchasedAmount);
    }

    calculateRemainingItems();
  }, [])
  useEffect(() => {
    async function checkConnection() {
      try {
        if (window && window.ethereum) {
          // Check if web3modal wallet connection is available on storage
          if (localStorage.getItem(web3modalStorageKey)) {
            // var a = await getXSUMONFT();
            // console.log(a)
            var seSumoArray = await getSESumoNFT();
            //se sumo airdrop reward
            var seAirdropAmount = seSumoArray.length * 1000
            var promises = await getXSUMONFTArray();
            var theXSumoArray = []
            var theXSumoAirdrop = 0
            await Promise.all(promises).then((values) => {
              var airdrop = 0
              var xSumoArray = []
              values.map((item, index) => {
                airdrop = item[0] !== 0 && item[1] <= 3000 ? airdrop + 1 : airdrop
                xSumoArray.push(item[1])
              })
              theXSumoArray = xSumoArray
              theXSumoAirdrop = airdrop * 1500
            })
            console.log(theXSumoArray, theXSumoAirdrop,seSumoArray,seAirdropAmount)
            //setXSUMOArray([...new Set(xsumoList)]);
          }
        } else {
          console.log('window or window.ethereum is not available');
        }
      } catch (error) {
        console.log(error, 'Catch error Account is not connected');
      }
    }
    checkConnection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleWalletConnect = async () => {
    if (!isMetaMaskInstalled()) {
      metamaskModalHandle();
    } else {
      walletModalHandle();
    }
  }
  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="sumotex_v1_baner_left">
              <h2 style={{ color: 'white' }}>Real World Asset Tokenisation.</h2>
              <h3 style={{ fontSize: 28 }}>255 million Total Vault Value Locked</h3>
              <h3>
                <span className="count">
                  $ {remaining == 0 ? "Loading..." : (remaining * 0.07).toFixed(0)}
                </span>{" "}
                / $2,100,000
              </h3>
              <div className="banner_buttons">
                {
                  account ?
                    <Button lg variant="mint" onClick={() => mintModalHandle()}>
                      {" "}
                      BUY now
                    </Button> :
                    <Button lg variant="mint" onClick={() => handleWalletConnect()}>
                      {" "}
                      Connect now
                    </Button>
                }
                <Button lg variant="outline" onClick={() => window.open("https://docs.sumotex.co/SUMOTEX_WHITEPAPER_V2.pdf", "_self")}>
                  WHITEPAPER
                </Button>

              </div>
              <div style={{ paddingTop: 20, display: 'flex', flexDirection: 'row' }}>
                <Button lg variant="outline" style={{ marginRight: 20 }} onClick={() => window.open("https://solidity.finance/audits/SUMOTEX", "_self")}>
                  AUDIT Report
                </Button>

              </div>
              <div style={{ paddingTop: 20, display: 'flex', flexDirection: 'row' }}>
                {
                  account ?
                    <Button lg variant="grey" onClick={() => claimModalHandle()}>
                      {" "}
                      Claim Purchase Token
                    </Button> : ""
                }
              </div>

              <div className="coin-info" style={{ paddingBottom: 100 }}>
                <span>0.07 USDC = 1 SMTX</span>
                <span>Contract Address:</span>
                <h2 style={{ fontSize: 16 }}>0x102203517ce35AC5Cab9a2cda80DF03f26c7419b</h2>
                <span>
                  PRESALE IS LIVE{" "}
                  <span className="highlighted">UNTIL SOLD OUT</span>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="sumotex_v1_baner_left">
              <span style={{ color: 'white' }}>SMTX solves the very fundemental issue of institutional finance:- illiquid nature of assets (Real Estates, unlisted company shares, bonds, fixed deposits). Often locked in from months to years, SMTX’s AssetFi tokenization protocol provides the solution to this.
                <br /><br />

                Imagine, a tokenized Real Estate will become borderless as there will be no geographical limitation to owning a piece of real estate. Furthermore, the barrier of entry to owning a piece of huge real estate/business is reduced as tokens are divisible.
                <br /><br />

                Fund managers and financial institutions who wish to liquidate/tokenise their funds or asset can do it on SMTX’s protocol. Institutional finance is poised for disruption, and SMTX is spearheading web3 to it.
              </span>
            </div>
          </div>
        </div>
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
