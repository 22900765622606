const data = [
    {
        num: "1",
        mintColor: "green",
        text: "Connect Your Wallet"
    },
    {
        num: "2",
        mintColor: "blue",
        text: "Select Your Quantity and ensure you have enough USDC/ETH"
    },
    {
        num: "3",
        mintColor: "yellow",
        text: "Approve USDC/ETH transaction."
    },
    {
        num: "4",
        mintColor: "red",
        text: "Click purchase and wait for the token unlocking period"
    },
]

export default data;