import Web3Modal from 'web3modal';
import preSaleContract from '../contracts/preSale.json';
import timeLockJSON from '../contracts/timeLock.json';
import timeLockETH from '../contracts/purchaseEthLock.json';
import erc20Contract from '../contracts/erc20.json';
import ethUSD from '../contracts/ethUSD.json';
import XSUMO from '../contracts/xsumoABI.json';
import SESUMO from '../contracts/seNFTABI.json';
import airDrop from '../contracts/airDrop.json';
import API from '../constants/API';
import { ethers } from 'ethers';
import { ethereum } from '../config';
export const web3modalStorageKey = 'WEB3_CONNECT_CACHED_PROVIDER';
export const web3Modal =
    typeof window !== 'undefined' && new Web3Modal({ cacheProvider: true });

/* This effect will fetch wallet address if user has already connected his/her wallet */

export const setWalletAddress = async (provider) => {
    try {
        const signer = provider.getSigner();
        if (signer) {
            const web3Address = await signer.getAddress();
            getBalance(provider, web3Address);
            return web3Address
        }
    } catch (error) {
        console.log(
            'Account not connected; logged from setWalletAddress function'
        );
    }
};

export const getBalance = async (provider, walletAddress) => {
    const walletBalance = await provider.getBalance(walletAddress);
    const balanceInEth = ethers.utils.formatEther(walletBalance);
    return (balanceInEth);
};

export const getChainId = async () => {
    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    return (parseInt(chainId))
};

export const disconnectWallet = () => {
    return (undefined);
    web3Modal && web3Modal.clearCachedProvider();
};


export const subscribeProvider = async (connection) => {
    connection.on('close', () => {
        disconnectWallet();
    });
    connection.on('accountsChanged', async (accounts) => {
        if (accounts?.length) {
            //setAddress(accounts[0]);
            const provider = new ethers.providers.Web3Provider(connection);
            getBalance(provider, accounts[0]);
        } else {
            disconnectWallet();
        }
    });
};
export const getXSUMONFT = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const xsumoContract = "0x7d150d3eb3ad7ab752df259c94a8ab98d700fc00";
    const coinContract = new ethers.Contract(xsumoContract, XSUMO, signer);
    const address = signer.getAddress();
    let theAddress = await coinContract.balanceOf(address)
    //let theAddress = await coinContract.balanceOf("0xc98361f39237f00562a9a7e4e7a401bd5efda434");
    //let total = ethers.utils.formatUnits(txnHash, 18)
    return parseInt(theAddress)
};
export const getSESumoNFT = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const seSumoContract = "0x9756e951dd76e933e34434db4ed38964951e588b";
    const coinContract = new ethers.Contract(seSumoContract, SESUMO, signer);
    const address = signer.getAddress();
    let theAddress = await coinContract.walletOfOwner(address);
    //let theAddress = await coinContract.walletOfOwner("0xc98361f39237f00562a9a7e4e7a401bd5efda434");

    if (theAddress instanceof Array) {
        var numberArray = [...theAddress]
        var result = numberArray.map(function (x) {
            return parseInt(x);
        });
        return result
        //sliceNFT(numberArray)
    }

    return parseInt(theAddress)
};
export const getXSUMONFTArray = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const xsumoContract = "0x7d150d3eb3ad7ab752df259c94a8ab98d700fc00";
    const coinContract = new ethers.Contract(xsumoContract, XSUMO, signer);
    const address = signer.getAddress();
    let xSumoBalance = await coinContract.balanceOf(address);
    //let xSumoBalance = await coinContract.balanceOf("0xc98361f39237f00562a9a7e4e7a401bd5efda434")
    if (Number(xSumoBalance) != 0) {
        let promises = []
        for (var i = 0; i < Number(xSumoBalance); i++) {
            //const theIndex = await coinContract.tokenOfOwnerByIndex("0x433d9c1dcfea21654cf962cecf36c9029f649c3a", i)
            //const theResult = await getXSumoClaimInfo(Number(theIndex))
            //console.log(Number(theIndex),theResult)
            promises.push(getTokenDetails(i))
        }
        return promises
        // return Promise.all(promises).then((values) => {
        //     var theItem = 0
        //     var airdrop = 0
        //     var xSumoArray=[]
        //     values.map((item, index) => {
        //         theItem += Number(item[0])
        //         airdrop = item[0] !== 0 && item[1] <= 3000 ? airdrop + 1 : airdrop
        //         xSumoArray.push(item[1])
        //     })
        //     return xSumoArray,airdrop*1500
        // })
    }else{
        return []
    }
}
export const getTokenDetails = (i) => {
    return new Promise((resolve, reject) => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const xsumoContract = "0x7d150d3eb3ad7ab752df259c94a8ab98d700fc00";
        const coinContract = new ethers.Contract(xsumoContract, XSUMO, signer);
        const address = signer.getAddress();
        //coinContract.tokenOfOwnerByIndex("0xc98361f39237f00562a9a7e4e7a401bd5efda434", i).then(xSumoNFTArray => {
        coinContract.tokenOfOwnerByIndex(address, i).then(xSumoNFTArray => {
            coinContract.fused(Number(xSumoNFTArray)).then(isFuse => {
                if (isFuse) {
                    coinContract.tokenURI(Number(xSumoNFTArray)).then(details => {
                        let itemDetails = String(details)
                        let theItem = itemDetails.replace("ipfs://", "https://sumotex.mypinata.cloud/ipfs/")
                        API.get(theItem).then(res => {
                            let theItemsNFT = (res.data)
                            let theImage = (theItemsNFT['image']).replace("ipfs://", "https://sumotex.mypinata.cloud/ipfs/")
                            if (Number(xSumoNFTArray) <= 10) {
                                //setGigaSumoDividend(gigaSumoDividend + 15000)

                                resolve([1, Number(xSumoNFTArray)]);

                            }
                            else if (Number(xSumoNFTArray) >= 11) {
                                resolve([0, Number(xSumoNFTArray)]);
                            } else {
                                resolve([0, Number(xSumoNFTArray)])
                            }
                        })

                    })
                } else {
                    const provider = new ethers.providers.Web3Provider(window.ethereum);
                    const signer = provider.getSigner();
                    const xsumoContract = "0x7d150d3eb3ad7ab752df259c94a8ab98d700fc00";
                    const coinContract = new ethers.Contract(xsumoContract, XSUMO, signer);
                    coinContract.tokenURI(Number(xSumoNFTArray)).then(details => {
                        let itemDetails = String(details)
                        let theItem = itemDetails.replace("ipfs://", "https://sumotex.mypinata.cloud/ipfs/")
                        API.get(theItem).then(res => {
                            let theItemsNFT = (res.data)
                            let theImage = (theItemsNFT['image']).replace("ipfs://", "https://sumotex.mypinata.cloud/ipfs/")
                            // setXSUMOArray(prevState => ([
                            //     ...prevState,
                            //     {
                            //         name: "Potion #" + Number(xSumoNFTArray),
                            //         dna: theItemsNFT.dna,
                            //         edition: Number(xSumoNFTArray),
                            //         rarity: 'TBD',
                            //         nftStyle: 'TBD',
                            //         image: theImage,
                            //         attributes: [],
                            //         dividendAmount: 0,
                            //         nftStyle: 'Potion',
                            //         rankingPercentile: 'TBD'
                            //     }
                            // ]))
                        })
                        resolve([0, Number(xSumoNFTArray)])
                    })
                }
            })

        })
    })
}
export const getClaimedAirdrop = async (item) => {
    await ethereum.enable();
    const { ethers } = require("ethers");
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    //const address = "0xA6F49C590A8e1AFbEbF5C245E1768435c7718bF6"
    const timeLockddress = "0x91DB8987ebA258c6Cf6bD7D86CAd40FeBDBf9A3b";
    const coinContract = new ethers.Contract(timeLockddress, airDrop, address);
    let txnHash = await coinContract.getXSumoClaimAddress(item)
    return txnHash

}
export const claimSMTXAirdropToken = async (address,amount, ogSumo, xSumo) => {
    await ethereum.enable();
    const { ethers } = require("ethers");
    const provider = new ethers.providers.Web3Provider(ethereum);
    //console.log(amount,ogSumo,xSumo)
    const signer = provider.getSigner();
    if (address==""){
        var theAddress = await signer.getAddress();
    }else{
        var theAddress = address
    }
    //const address = "0xA6F49C590A8e1AFbEbF5C245E1768435c7718bF6"
    const timeLockddress = "0x91DB8987ebA258c6Cf6bD7D86CAd40FeBDBf9A3b";
    const coinContract = new ethers.Contract(timeLockddress, airDrop, signer);
    const secretPhase = "214125442A472D4B6150645367566B59"

    let txnHash = await coinContract.claimAirdropToken(theAddress, amount, ogSumo, xSumo, secretPhase);
    try {
        // Wait for the transaction to be mined
        const receipt = await txnHash.wait();
        // The transactions was mined without issue
        return 1
    } catch (error) {
        return 1
    }
}
export const getSESUMO = async (array) => {
    var theTotalDailyAirdrop = 0
    var theSEArray = []
    var theAirdropAmount = 0
    array.map(async (item, index) => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const seSumoContract = "0x9756e951dd76e933e34434db4ed38964951e588b";
        const coinContract = new ethers.Contract(seSumoContract, SESUMO, signer);
        let details = await coinContract.tokenURI(item)
        let itemDetails = String(details)
        var last = itemDetails.substring(itemDetails.lastIndexOf("/") + 1, itemDetails.length);
        var url = ('https://sumotex.mypinata.cloud/ipfs/Qme4K7ZKYaXTKSVX9Pwthauqu2P86wTvbBHtkdqmSPGo73/' + last)
        API.get(url).then((response) => {
            //await sleep(800);
            var theItem = response.data
            var theImage = theItem.image.substring(theItem.image.lastIndexOf("/") + 1, theItem.image.length);
            var stringImage = 'https://sumotex.mypinata.cloud/ipfs/QmXhVqBXvEVr9Ev1E6GyiTDUC78CcMsZx1FVZq1KieQu85/' + theImage
            var theDate = new Date(theItem.date).toLocaleString() + ''
            var rarity = calculateRarity(theItem.attributes, 100000)
            theTotalDailyAirdrop += rarity.dividend == 25 ? 5.56 : rarity.dividend == 15 ? 0.67 : rarity.dividend == 12 ? 0.52 : rarity.dividend == 7 ? 0.27 : 0.22
            theSEArray.push(
                {
                    name: theItem.name,
                    dna: theItem.dna,
                    rewards: rarity.dividend == 25 ? 5.56 : rarity.dividend == 15 ? 0.67 : rarity.dividend == 12 ? 0.52 : rarity.dividend == 7 ? 0.27 : 0.22,
                    edition: theItem.edition,
                    image: stringImage,
                    attributes: theItem.attributes,
                    dividendAmount: rarity.dividend,
                    nftStyle: rarity.type,
                    rankingPercentile: (Number(rarity.ranking)),
                    mintedDate: theDate
                }
            )
        }).catch(error => {
            console.log(error)
        })
        return theSEArray, theTotalDailyAirdrop
    });


}
export const calculateRarity = (items, totalNFT) => {
    var avg = 10
    var theAvg = 0
    var theDividend = 0
    items.map((item, index) => (
        theAvg += (item.count / totalNFT)
    ))
    if (items[5].value.split('_')[0] !== 'Common') {
        //hat
        theDividend = 25
    } else if (items[2].value.split('_')[0] !== 'common') {
        //eye
        theDividend = 15
    } else if (items[7].value.split('_')[0] !== 'common') {
        //nipple
        theDividend = 12
    } else if (items[8].value.split('_')[0] !== 'common' && items[6].value.split('_')[0] !== 'common') {
        // ear and mouth
        theDividend = 7
    } else {
        theDividend = 1
    }
    var theWeightRanking = 100 - ((theAvg / avg) * 100)

    if (theDividend === 25) {
        return { dividend: 25, type: 'Emperor', ranking: theWeightRanking }
    }
    else if (theDividend === 15) {
        return { dividend: 15, type: 'King', ranking: theWeightRanking }
    }
    else if (theDividend === 12) {
        return { dividend: 12, type: 'Knight', ranking: theWeightRanking }
    }
    else if (theDividend === 7) {
        return { dividend: 7, type: 'Soldier', ranking: theWeightRanking }
    }
    else if (theDividend === 1) {
        return { dividend: 1, type: 'Minion', ranking: theWeightRanking }
    }
    else return { dividend: 0, ranking: 'error' }
}
export const calculateXSUMORarity = (items, totalNFT) => {
    var avg = 10
    var theAvg = 0
    var theDividend = 0
    var theAttributes = []
    items.map((item, index) => (
        theAvg += (item.count / totalNFT)
    ))
    items.map((item, index) => (
        theAttributes.push(item.value + " " + items.frequency)
    ))
    if (items[0].trait_type.split('/')[0] == 'Emperor') {
        theDividend = 25
    } else if (items[0].trait_type.split('/')[0] == 'King') {
        //eye
        theDividend = 15
    } else if (items[0].trait_type.split('/')[0] == 'Knight') {
        //nipple
        theDividend = 12
    } else if (items[0].trait_type.split('/')[0] == 'Soldier') {
        // ear and mouth
        theDividend = 7
    } else {
        theDividend = 1
    }
    var theWeightRanking = 100 - ((theAvg / avg) * 100)

    if (theDividend === 25) {
        return { dividend: 25, type: 'Emperor', ranking: theWeightRanking, attribute: theAttributes }
    }
    else if (theDividend === 15) {
        return { dividend: 15, type: 'King', ranking: theWeightRanking, attribute: theAttributes }
    }
    else if (theDividend === 12) {
        return { dividend: 12, type: 'Knight', ranking: theWeightRanking, attribute: theAttributes }
    }
    else if (theDividend === 7) {
        return { dividend: 7, type: 'Soldier', ranking: theWeightRanking, attribute: theAttributes }
    }
    else if (theDividend === 1) {
        return { dividend: 1, type: 'Minion', ranking: theWeightRanking, attribute: theAttributes }
    }
    else return { dividend: 0, ranking: 'error' }
}


export const getAddress = async () => {
    await ethereum.enable();
    const { ethers } = require("ethers");
    const provider = new ethers.providers.Web3Provider(ethereum);
    let accounts = await provider.send("eth_requestAccounts", []);
    let account = accounts[0];
    provider.on('accountsChanged', function (accounts) {
        account = accounts[0];
    });
    return account
}
export const getXSumoClaimInfo = async (arrayList) => {
    await ethereum.enable();
    let theArray = [];
    let officialList=[];
    for (let i = 0; i <= (arrayList.length); i++) {
            if (arrayList[i] > 10) {
                theArray.push(getClaimXSUMOInformation(arrayList[i]));
                //console.log(theArray)
            }
    }
    await Promise.all(theArray).then((values) => {
        values.map((item,index)=>{
            if(item[0]==1){
                officialList.push(item[1])
            }
        })
    }).catch(e=>{
        console.log(e)
    })
    return officialList
}
export const getGigaSumoClaimInfo = async (arrayList) => {
    await ethereum.enable();
    var theArray = [];
    let officialList=[];
    for (let i = 0; i < Number(arrayList.length); i++) {
        if (arrayList[i] <= 10) {
            theArray.push(getClaimXSUMOInformation(arrayList[i]));
        }
    }
    await Promise.all(theArray).then((values) => {
        console.log(values)
        values.map((item,index)=>{
            if(item[0]==1){
                officialList.push(item[1])
               
            }
        })
    }).catch(e=>{
        console.log(e)
    })
    return officialList
}
export const getOgSumoClaimInfo = async (array) => {
    await ethereum.enable();
    const { ethers } = require("ethers");
    const theArray = [];
    let officialList=[];
    for (var i = 0; i < Number(array.length); i++) {
        theArray.push(getClaimOGSUMOInformation(array[i]));
    }
    await Promise.all(theArray).then((values) => {
        values.map((item,index)=>{
            if(item[0]==1){
                officialList.push(item[1])
            }
        })
    }).catch(e=>{
        console.log(e)
    })
    return officialList
}
export const getClaimOGSUMOInformation = async (i) => {
    return new Promise((resolve, reject) => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const timeLockddress = "0x91DB8987ebA258c6Cf6bD7D86CAd40FeBDBf9A3b";
        const coinContract = new ethers.Contract(timeLockddress, airDrop, provider);
        const address = signer.getAddress();
        coinContract.getOgSumoClaimInfo(i).then(theValue => {
                if (theValue == false) {
                    //resolve([1, array[i]]);
                    resolve([1,i])
                }else{
                    resolve([0,i])
                }
            })

        })
    }
export const getClaimXSUMOInformation = async (i) => {
    return new Promise((resolve, reject) => {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const timeLockddress = "0x91DB8987ebA258c6Cf6bD7D86CAd40FeBDBf9A3b";
        const coinContract = new ethers.Contract(timeLockddress, airDrop, provider);
        const address = signer.getAddress();
        
        coinContract.getXSumoClaimInfo(i).then(theValue => {
                if (theValue == false) {
                    //resolve([1, array[i]]);
                    resolve([1,i])
                }else{
                    resolve([0,i])
                }
            })

        })
    }
export const getAvailableAirdropAmount = async () => {
    await ethereum.enable();
    const { ethers } = require("ethers");
    const provider = new ethers.providers.Web3Provider(ethereum);
    //const provider = new ethers.getDefaultProvider("homestead");
    await provider.send('eth_requestAccounts', []);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    //const address = "0x433d9c1dcfea21654cf962cecf36c9029f649c3a";
    const timeLockContract = "0x91DB8987ebA258c6Cf6bD7D86CAd40FeBDBf9A3b";
    const coinContract = new ethers.Contract(timeLockContract, timeLockJSON, signer);
    let txnHash = await coinContract.getTotalAvailableAmount(address)
    let total = ethers.utils.formatUnits(txnHash, 18)
    //40565147724444444444444444
    
    //936503000000000000000000
    return parseInt(total)
}
export const getWithdrawalAmountAirdrop = async () => {
    await ethereum.enable();
    const { ethers } = require("ethers");
    const provider = new ethers.providers.Web3Provider(ethereum);
    //const provider = new ethers.getDefaultProvider("homestead");
    const signer = provider.getSigner();
    const address = signer.getAddress();
    //const address= "0x433d9c1dcfea21654cf962cecf36c9029f649c3a"
    const timeLockContract = "0x91DB8987ebA258c6Cf6bD7D86CAd40FeBDBf9A3b";
    const coinContract = new ethers.Contract(timeLockContract, airDrop, signer);
    let txnHash = await coinContract.getWithdrawalAmount(address);
    let total = ethers.utils.formatUnits(txnHash, 18);
    return total
}
export const getWithdrawalAmountAirdropNotVested = async () => {
    await ethereum.enable();
    const { ethers } = require("ethers");
    const provider = new ethers.providers.Web3Provider(ethereum);
    //const provider = new ethers.getDefaultProvider("homestead");
    const signer = provider.getSigner();
    const address = signer.getAddress();
    const timeLockContract = "0x91DB8987ebA258c6Cf6bD7D86CAd40FeBDBf9A3b";
    const coinContract = new ethers.Contract(timeLockContract, airDrop, signer);
    let txnHash = await coinContract.getWithdrawalAmountNotVested(address);
    let total = ethers.utils.formatUnits(txnHash, 18);
    return total
}
export const purchaseCoin = async (purchase_amount, usd_amount) => {
    await ethereum.enable();
    const { ethers } = require("ethers");
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    const timeLockddress = "0x913359ef75272E8618f1C0C17c811238B25890Ee";
    const coinContract = new ethers.Contract(timeLockddress, preSaleContract, signer);
    let txnHash = await coinContract.purchaseToken(address, purchase_amount, usd_amount, {
        value: 0
    })
    try {
        // Wait for the transaction to be mined
        const receipt = await txnHash.wait();
        // The transactions was mined without issue
        return 1
    } catch (error) {
        return 1
    }
}


export const purchaseCoinWithETH = async (purchase_amount, eth_amount) => {
    await ethereum.enable();
    const { ethers } = require("ethers");
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    //eth Contract timelock
    const timeLockAddress = "0x7B39215D789CF810435F87bc3bE76B9af7eC6FE9";
    const coinContract = new ethers.Contract(timeLockAddress, timeLockETH, signer);
    let txnHash = await coinContract.purchaseTokenWithETH(address, purchase_amount, {
        value: eth_amount

    })
    try {
        // Wait for the transaction to be mined
        const receipt = await txnHash.wait();
        // The transactions was mined without issue
        return 1
    } catch (error) {
        return 1
    }
}
export const approved = async (purchase_amount) => {
    await ethereum.enable();
    const { ethers } = require("ethers");
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const usdtContract = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
    const timeLockContract = "0x913359ef75272E8618f1C0C17c811238B25890Ee";
    const coinContract = new ethers.Contract(usdtContract, erc20Contract, signer);
    let txnHash = await coinContract.approve(timeLockContract, String(purchase_amount * 1000000), {
        value: 0
    })
    try {
        // Wait for the transaction to be mined
        const receipt = await txnHash.wait();
        // The transactions was mined without issue
        return 1
    } catch (error) {
        return 1
    }
}
export const getWithdrawalAmountETH = async () => {
    await ethereum.enable();
    const { ethers } = require("ethers");
    const provider = new ethers.providers.Web3Provider(ethereum);
    //const provider = new ethers.getDefaultProvider("homestead");
    const signer = provider.getSigner();
    const address = signer.getAddress();
    const timeLockETHContract = "0x7B39215D789CF810435F87bc3bE76B9af7eC6FE9";
    const coinContract2 = new ethers.Contract(timeLockETHContract, timeLockJSON, signer);
    let txnHash = await coinContract2.getWithdrawalAmount(address)
    let total = ethers.utils.formatUnits(txnHash, 18)
    return total
}
export const getWithdrawalAmountUSDC = async () => {
    await ethereum.enable();
    const { ethers } = require("ethers");
    const provider = new ethers.providers.Web3Provider(ethereum);
    //const provider = new ethers.getDefaultProvider("homestead");
    const signer = provider.getSigner();
    const address = signer.getAddress();
    const timeLockContract = "0x913359ef75272E8618f1C0C17c811238B25890Ee";
    const coinContract = new ethers.Contract(timeLockContract, timeLockJSON, signer);
    let txnHash = await coinContract.getWithdrawalAmount(address)
    let total = ethers.utils.formatUnits(txnHash, 18)
    return total
}
export const getAvailableAmount = async () => {
    await ethereum.enable();
    const { ethers } = require("ethers");
    const provider = new ethers.providers.Web3Provider(ethereum);
    //const provider = new ethers.getDefaultProvider("homestead");
    await provider.send('eth_requestAccounts', []);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    //console.log(address)
    const timeLockContract = "0x913359ef75272E8618f1C0C17c811238B25890Ee";
    const timeLockETHContract = "0x7B39215D789CF810435F87bc3bE76B9af7eC6FE9";
    const coinContract = new ethers.Contract(timeLockContract, timeLockJSON, signer);
    const coinContract2 = new ethers.Contract(timeLockETHContract, timeLockJSON, signer);
    let txnHash2 = await coinContract2.getTotalAvailableAmount(address)
    let txnHash = await coinContract.getTotalAvailableAmount(address)
    let total = ethers.utils.formatUnits(txnHash, 18)
    let total2 = ethers.utils.formatUnits(txnHash2, 18)

    return parseInt(total) + parseInt(total2)
}
export const getPurchasedAmount = async () => {
    const { ethers } = require("ethers");
    const provider = new ethers.getDefaultProvider("homestead");
    //const signer = provider.getSigner();
    const timeLockContract = "0x913359ef75272E8618f1C0C17c811238B25890Ee";
    const timeLockETHContract = "0x7B39215D789CF810435F87bc3bE76B9af7eC6FE9";
    const coinContract = new ethers.Contract(timeLockContract, timeLockJSON, provider);
    const coinContract2 = new ethers.Contract(timeLockETHContract, timeLockJSON, provider);
    let txnHash = await coinContract.getTotalSaleAvailable()
    let txnHash2 = await coinContract2.getTotalSaleAvailable()
    let total = ethers.utils.formatUnits(txnHash, 18)
    let total2 = ethers.utils.formatUnits(txnHash2, 18)
    return parseInt(total) + parseInt(total2)
}
export const transferTokenAfterUnlockAirdrop = async (weiAmount) => {
    await ethereum.enable();
    const { ethers } = require("ethers");
    //const provider = new ethers.getDefaultProvider("homestead");
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    const SMTXcontractAddress = "0x3C1E3B0Ad165A4bB19aee73eAddC5919996d4E8B";
    //goerli
    //const SMTXcontractAddress = "0xbf5C5d2B21dF87C7BACa260e9ae928Ff218Ae418";
    const timeLockContract = "0x91DB8987ebA258c6Cf6bD7D86CAd40FeBDBf9A3b";
    const coinContract = new ethers.Contract(timeLockContract, timeLockJSON, signer);
    let txnHash = await coinContract.transferTimeLockedTokensAfterTimePeriod(SMTXcontractAddress, address, weiAmount, {
    })
    try {
        // Wait for the transaction to be mined
        const receipt = await txnHash.wait();
        // The transactions was mined without issue
        return 1
    } catch (error) {
        return 1
    }
}

export const transferTokenAfterUnlockUSDC = async (weiAmount) => {
    await ethereum.enable();
    const { ethers } = require("ethers");
    //const provider = new ethers.getDefaultProvider("homestead");
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    const SMTXcontractAddress = "0x102203517ce35AC5Cab9a2cda80DF03f26c7419b";
    const timeLockContract = "0x913359ef75272E8618f1C0C17c811238B25890Ee";
    const coinContract = new ethers.Contract(timeLockContract, timeLockJSON, signer);
    let txnHash = await coinContract.transferTimeLockedTokensAfterTimePeriod(SMTXcontractAddress, address, weiAmount, {
    })
    try {
        // Wait for the transaction to be mined
        const receipt = await txnHash.wait();
        // The transactions was mined without issue
        return 1
    } catch (error) {
        return 1
    }
}
export const transferTokenAfterUnlockETH = async (weiAmount) => {
    await ethereum.enable();
    const { ethers } = require("ethers");
    const provider = new ethers.providers.Web3Provider(ethereum);
    //const provider = new ethers.getDefaultProvider("homestead");
    const signer = provider.getSigner();
    //const address = "0x1bf5adbee52b9cc4b22213bf62ddf59d11c4272e";
    const address = await signer.getAddress();
    const SMTXcontractAddress = "0x102203517ce35AC5Cab9a2cda80DF03f26c7419b";
    const timeLockETHContract = "0x7B39215D789CF810435F87bc3bE76B9af7eC6FE9";
    const coinContract = new ethers.Contract(timeLockETHContract, timeLockJSON, signer);
    let txnHash = await coinContract.transferTimeLockedTokensAfterTimePeriod(SMTXcontractAddress, address, weiAmount, {
    })
    try {
        // Wait for the transaction to be mined
        const receipt = await txnHash.wait();
        // The transactions was mined without issue
        return 1
    } catch (error) {
        return 1
    }
}
export const getChainLinkETHUSDPrice = async () => {
    const { ethers } = require("ethers");
    const provider = new ethers.providers.Web3Provider(ethereum);
    //const provider = new ethers.getDefaultProvider("homestead");
    const signer = provider.getSigner();
    const chainLinkContractAddress = "0x5f4ec3df9cbd43714fe2740f5e3616155c5b8419";
    const coinContract = new ethers.Contract(chainLinkContractAddress, ethUSD, signer);
    let thePrice = await coinContract.latestRoundData()
    return thePrice['answer'];
}
export const getApprovalForUSDC = async () => {
    await ethereum.enable();
    const { ethers } = require("ethers");
    const provider = new ethers.providers.Web3Provider(ethereum);
    //const provider = new ethers.getDefaultProvider("homestead");
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    const usdcContract = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48";
    const theContract = new ethers.Contract(usdcContract, erc20Contract, signer);
    let theApproval = await theContract.allowance(address, "0x913359ef75272E8618f1C0C17c811238B25890Ee")
    return ethers.utils.formatUnits(theApproval, 6);
}





