import { Slider, SliderItem } from "../../../../common/slider/Slider";
import SectionTitle from "../../../../common/sectionTitle";
import data from "../../../../assets/data/characterSlider/characterSlider";

import CharacterSliderWrapper from "./CharacterSlider.style";

const CharacterSlider = () => {
  const settings = {
    dots: true,
    arrows: false,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    centerMode: true,
    centerPadding: "0px",
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 641,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <CharacterSliderWrapper>
      <SectionTitle
        isCenter={true}
        subtitle="Featured On"
        title=""
        className="text-center"
      />
      <Slider {...settings} style={{ paddingTop: 50 }} >
        {data?.map((item, i) => (
          <SliderItem key={i} >
            <div style={{ maxWidth: 200, display: 'flex' }}>
              <a onClick={() => window.open(item.link, "_open")}><img src={item.thumb} alt="sumotex news" /></a>
            </div>
          </SliderItem>
        ))}
      </Slider>
    </CharacterSliderWrapper>
  );
};

export default CharacterSlider;
