import {
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaTelegramPlane,
  FaFacebook,
} from "react-icons/fa";

import openseaIcon from "../images/icon/opensea.svg";
import mediumIcon from "../images/icon/med.svg";

const data = [
  {
    icon: <FaTwitter />,
    url: "https://twitter.com/SumoTex",
  },
  {
    icon: <FaTelegramPlane />,
    url: "https://t.me/SumoTex",
  }
];

export default data;
