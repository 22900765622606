import { useModal } from "../utils/ModalContext";
import GlobalStyles from "../assets/styles/GlobalStyles";
import Header from "../components/section/header/v1/Header";
import Layout from "../common/layout";
import Banner from "../components/section/banner/v1";
import Counter from "../components/section/counter";
import Claim from "../components/section/claim";
import HowToMint from "../components/section/howToMint/v1";
import TokenDistribution from "../components/section/tokenDistribution";
import About from "../components/section/about/v1";
import RoadMap from "../components/section/roadMap/v1";
import Team from "../components/section/team/v1";
import FAQ from "../components/section/faq/v1";
import Footer from "../components/section/footer/v1";
import Partner from "../components/section/partner/Partner";
import CharacterSlider from "../components/section/characterSlider/v1/CharacterSlider";
import MintNowModal from "../common/modal/mintNowModal";
import WalletModal from "../common/modal/walletModal/WalletModal";
import ClaimNowModal from "../common/modal/claimNowModal";
import MetamaskModal from "../common/modal/metamask/MetamaskModal";
import ClaimAirdropModal from "../common/modal/claimAirdropModal/ClaimAirdropModal";
import ConnectWallet from "../common/modal/metamask/ConnectWallet";

const HomeV1 = () => {
  const { visibility, walletModalvisibility, claimModalvisibility, claimAirdropModalvisibility,metamaskModalVisibility, connectWalletModal } = useModal();
  return (
    <Layout>
      <GlobalStyles />
      {visibility && <MintNowModal />}
      {claimModalvisibility && <ClaimNowModal />}
      {claimAirdropModalvisibility && <ClaimAirdropModal/>}
      {walletModalvisibility && <WalletModal />}
      {metamaskModalVisibility && <MetamaskModal />}
      {connectWalletModal && <ConnectWallet />}
      <Header />
      <Banner />

      <Claim />

      <Counter /> 

      <CharacterSlider />

      <HowToMint />

      <About />

      <RoadMap />
      <TokenDistribution />
      <Team />
      <FAQ />
      <Footer />
    </Layout>
  );
};

export default HomeV1;
