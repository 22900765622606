import { useState } from "react";
import { useModal } from "../../../utils/ModalContext";
import { FiX } from "react-icons/fi";
import Button from "../../button";
import ClaimAirdropModalStyleWrapper from "./ClaimAirdrop.style";
import ReactLoading from 'react-loading';
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";
import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
import { getWithdrawalAmountAirdropNotVested, transferTokenAfterUnlockAirdrop, getWithdrawalAmountAirdrop, getAvailableAirdropAmount, getAddress, getGigaSumoClaimInfo, getOgSumoClaimInfo, getXSumoClaimInfo, transferTokenAfterUnlockETH, getChainId, getXSUMONFTArray, getSESumoNFT, claimSMTXAirdropToken } from '../../../utils/web3mint';
import { useEffect } from "react";
import { ethereum } from "../../../config";
import { RotatingSquare } from 'react-loader-spinner';

const ClaimAirdropModal = () => {
  const [message, setMessage] = useState('');
  const [flow, setFlow] = useState(0);
  const [purchaseStatus, setPurchaseStatus] = useState(0);
  const [chainId, setChainId] = useState(1000);
  const [smtxWei, setSMTXWei] = useState(0);
  const [gigaSumoAirdrop, setGigaSumoAirdrop] = useState(0);
  const [xsumoAirdrop, setXSUMOAirdrop] = useState(0);
  const [ogsumoAirdrop, setOGSUMOAirdrop] = useState(0);
  const [loading, setLoading] = useState(false);
  const [unclaimOG, setUnclaimOG] = useState('');
  const [unclaimX, setUnclaimX] = useState('');
  const [unclaimXList, setUnclaimXList] = useState('');
  const [unclaimGigaList, setUnclaimGigaList] = useState('');
  const [unclaimOgList, setUnclaimOgList] = useState('');
  const [totalClaimed, setTotalClaimed] = useState(0);
  const [loadingClaim, setLoadingClaim] = useState(true);
  const [loadingUnClaim, setLoadingUnClaim] = useState(true);
  const [loadingCheck, setLoadingCheck] = useState(true);
  const [airdropAmount, setAirdrop] = useState(0);
  const [theClaimedAmountFlow4, setClaimedAmountFlow4] = useState(0);
  const [unlockedAmountFlow4, setUnlockedAmountFlow4] = useState(0);
  const { claimAirdropModalHandle } = useModal();

  useEffect(() => {
    async function checkConnection() {
      try {
        setLoadingClaim(true)
        var theFlow = await JSON.parse(localStorage.getItem("flow")) ? await JSON.parse(localStorage.getItem("flow")) : 0;
        setFlow(theFlow);
        if (window && window.ethereum && theFlow == 1) {
          localStorage.removeItem('gigaAirdrop');
          localStorage.removeItem('seAirdrop');
          localStorage.removeItem('xAirdrop');
          localStorage.removeItem('xArray');
          localStorage.removeItem('ogArray');
          localStorage.removeItem('address');
          localStorage.setItem("flow", 1);
          var theID = await getChainId();
          if (theID == 4689) {
            setFlow(1);
            await localStorage.setItem("flow", JSON.stringify(1));
          }
          setChainId(theID)
          await localStorage.setItem("chainId", JSON.stringify(theID));
          var seSumoArray = await getSESumoNFT();

          //se sumo airdrop reward
          var seAirdropAmount = seSumoArray.length * 1000
          var promises = await getXSUMONFTArray();
          var theXSumoArray = []
          var theXSumoAirdrop = 0
          var gigaAirdrop = 0
          var airdrop = 0
          if (promises.length != 0) {
            await Promise.all(promises).then((values) => {
              var xSumoArray = []
              values.map((item, index) => {
                airdrop = item[0] !== 0 && item[1] <= 3000 ? airdrop + 1 : airdrop
                xSumoArray.push(item[1])
              })
              values.map((item, index) => {
                gigaAirdrop += item[0] == 1 ? 15000 : 0
              })
              values.map((item, index) => {
                airdrop += item[0] == 0 ? 1500 : 0
              })
              theXSumoArray = xSumoArray
              theXSumoAirdrop = airdrop + gigaAirdrop
            })
          }

          setGigaSumoAirdrop(gigaAirdrop);
          setOGSUMOAirdrop(seAirdropAmount);
          setXSUMOAirdrop(airdrop);
          // var c = await getClaimedAirdrop();
          var address = await getAddress();
          await localStorage.setItem("gigaAirdrop", JSON.stringify(gigaAirdrop));
          await localStorage.setItem("seAirdrop", JSON.stringify(seAirdropAmount));
          await localStorage.setItem("xAirdrop", JSON.stringify(airdrop));
          await localStorage.setItem("xArray", JSON.stringify(theXSumoArray));
          await localStorage.setItem("ogArray", JSON.stringify(seSumoArray));
          await localStorage.setItem("address", JSON.stringify(address));
          setLoadingClaim(false);
          // window.location.reload();

          //console.log(theXSumoArray, theXSumoAirdrop, seSumoArray, seAirdropAmount)
          //setXSUMOArray([...new Set(xsumoList)]);
        }
      } catch (error) {
        setLoadingClaim(false);
        console.log(error, 'Catch error Account is not connected');
      }
    }

    checkConnection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  provider.on('chainChanged', chainId => {
    setChainId(chainId);
    window.location.reload();
  });
  // useEffect(() => {
  //   setInterval(function () {
  //     checkChainID();
  //   }, 100)
  //   async function checkChainID() {
  //     var a = await getChainId();
  //     if (a != chainId) {
  //       console.log(a, chainId)
  //       setChainId(a);
  //       //window.location.reload();
  //     }
  //   }
  // }, [chainId])
  useEffect(() => {
    async function checkConnection() {
      if (flow == 2) {
        setFlow(2)
        setLoadingCheck(true)
        setLoadingUnClaim(true)
        const a = await JSON.parse(localStorage.getItem("gigaAirdrop"));
        const b = await JSON.parse(localStorage.getItem("seAirdrop"));
        const c = await JSON.parse(localStorage.getItem("xAirdrop"));
        const d = await JSON.parse(localStorage.getItem("xArray"));
        const e = await JSON.parse(localStorage.getItem("ogArray"));
        const theID = await JSON.parse(localStorage.getItem("chainId"));
        var f = await getXSumoClaimInfo(d);
        var h = await getGigaSumoClaimInfo(d);
        var g = await getOgSumoClaimInfo(e);
        console.log(f, h, g)
        if (f.length != 0) {
          setUnclaimX((f.length * 1500));
          setUnclaimXList(f);
        }
        if (h.length != 0) {
          setUnclaimX((h.length * 15000));
          setUnclaimGigaList(h);
        }
        if (h.length != 0 && f.length != 0) {
          setUnclaimX((f.length * 1500) + (h.length * 15000));
          setUnclaimGigaList(h);
        }
        if (g.length != 0) {
          setUnclaimOG(g.length * 1000);
          setUnclaimOgList(g);
        }
        if (h.length == 0 && f.length == 0) {
          setUnclaimX(0);
        }
        if (h.length == 0 && f.length == 0 && g.length == 0) {
          setTotalClaimed(a + b + c)
        } else {
          setTotalClaimed(0)
        }
        let theClaimedSMTX = await getAvailableAirdropAmount();
        await localStorage.setItem("flow", JSON.stringify(2));
        setLoadingUnClaim(false)
        setLoadingCheck(false)
      }

    }
    if (flow == 3) {
      redeem();
      setFlow(3)
    }
    checkConnection();
  }, [flow])
  const checkClaims = async () => {
    const { ethers, utils } = require("ethers");
    // try {
    //   await window.ethereum.request({
    //     method: 'wallet_switchEthereumChain',
    //     params: [{ chainId: utils.hexValue(1) }]
    //   });
    //   await localStorage.setItem("flow", JSON.stringify(flow+1));
    //   window.location.reload();
    // } catch (err) {
    //   // This error code indicates that the chain has not been added to MetaMask
    //   if (err.code === 4902) {
    //     await window.ethereum.request({
    //       method: 'wallet_addEthereumChain',
    //       params: [
    //         {
    //           chainName: 'ETH',
    //           chainId: utils.hexValue(1),
    //           nativeCurrency: { name: 'ETH', decimals: 18, symbol: 'eth' },
    //           rpcUrls: ['https://mainnet.infura.io/v3/']
    //         }
    //       ]
    //     });
    //     await localStorage.setItem("flow", JSON.stringify(flow+1));
    //     window.location.reload();
    //   }
    // }
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: utils.hexValue(1) }]
      });
      window.location.reload();
      setLoadingUnClaim(true);
      const a = await JSON.parse(localStorage.getItem("gigaAirdrop"));
      const b = await JSON.parse(localStorage.getItem("seAirdrop"));
      const c = await JSON.parse(localStorage.getItem("xAirdrop"));
      const d = await JSON.parse(localStorage.getItem("xArray"));
      const e = await JSON.parse(localStorage.getItem("ogArray"));
      const theID = await JSON.parse(localStorage.getItem("chainId"));
      const theflow = await localStorage.getItem("flow");
      await localStorage.setItem("flow", JSON.stringify(2));
      setFlow(2);
      var f = await getXSumoClaimInfo(d);
      var h = await getGigaSumoClaimInfo(d);
      var g = await getOgSumoClaimInfo(e);
      console.log(f, h, g)
      if (f.length != 0) {
        setUnclaimX((f.length * 1500));
        setUnclaimXList(f);
      }
      if (h.length != 0) {
        setUnclaimX((h.length * 15000));
        setUnclaimGigaList(h);
      }
      if (h.length != 0 && f.length != 0) {
        setUnclaimX((f.length * 1500) + (h.length * 15000));
        setUnclaimGigaList(h);
      }
      if (g.length != 0) {
        setUnclaimOG(g.length * 1000);
        setUnclaimOgList(g);
      }
      if (h.length == 0 && f.length == 0) {
        setUnclaimX(0);
      }
      setLoadingUnClaim(false)

    } catch (err) {
      // This error code indicates that the chain has not been added to MetaMask
      if (err.code === 4902) {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainName: 'ETH',
              chainId: utils.hexValue(1),
              nativeCurrency: { name: 'Etheruem', decimals: 18, symbol: 'ETH' },
              rpcUrls: ['https://mainnet.infura.io/v3/']
            }
          ]
        });
        window.location.reload();
        setLoadingUnClaim(true)
        const a = await JSON.parse(localStorage.getItem("gigaAirdrop"));
        const b = await JSON.parse(localStorage.getItem("seAirdrop"));
        const c = await JSON.parse(localStorage.getItem("xAirdrop"));
        const d = await JSON.parse(localStorage.getItem("xArray"));
        const e = await JSON.parse(localStorage.getItem("ogArray"));
        const theID = await JSON.parse(localStorage.getItem("chainId"));
        await localStorage.setItem("flow", JSON.stringify(2));
        setFlow(2);
        var f = await getXSumoClaimInfo(d);
        var h = await getGigaSumoClaimInfo(d);
        var g = await getOgSumoClaimInfo(e);
        if (f.length != 0) {
          setUnclaimX(unclaimX + (f.length * 1500));
          setUnclaimXList(f);
        }
        if (h.length != 0) {
          setUnclaimX(unclaimX + (h.length * 15000));
          setUnclaimGigaList(h);
        }
        if (h.length != 0 && f.length != 0) {
          setUnclaimX((f.length * 1500) + (h.length * 15000));
          setUnclaimGigaList(h);
        }
        if (g.length != 0) {
          setUnclaimOG(g.length * 1000);
          setUnclaimOgList(g);
        }
        if (h.length == 0 && f.length == 0) {
          setUnclaimX(0);
        }
        setLoadingUnClaim(false)
      }
    }
    setLoadingCheck(true)
    await localStorage.setItem("flow", JSON.stringify(flow + 1));
    const a = await JSON.parse(localStorage.getItem("gigaAirdrop"));
    const b = await JSON.parse(localStorage.getItem("seAirdrop"));
    const c = await JSON.parse(localStorage.getItem("xAirdrop"));
    const d = await JSON.parse(localStorage.getItem("xArray"));
    const e = await JSON.parse(localStorage.getItem("ogArray"));
    const theID = await JSON.parse(localStorage.getItem("chainId"));
    await localStorage.setItem("flow", JSON.stringify(2));
    setFlow(2);
    var f = await getXSumoClaimInfo(d);
    var h = await getGigaSumoClaimInfo(d);
    var g = await getOgSumoClaimInfo(e);
    if (f.length != 0) {
      setUnclaimX((f.length * 1500));
      setUnclaimXList(f);
    }
    if (h.length != 0) {
      setUnclaimX((h.length * 15000));
      setUnclaimGigaList(h);
    }
    if (h.length != 0 && f.length != 0) {
      setUnclaimX((f.length * 1500) + (h.length * 15000));

      setUnclaimGigaList(h);
    }
    if (g.length != 0) {
      setUnclaimOG(g.length * 1000);
      setUnclaimOgList(g);
    }
    if (h.length == 0 && f.length == 0) {
      setUnclaimX(0);
    }

    setLoadingCheck(false)
    // const a = await JSON.parse(localStorage.getItem("gigaAirdrop"));
    // const b = await JSON.parse(localStorage.getItem("seAirdrop"));
    // const c = await JSON.parse(localStorage.getItem("xAirdrop"));
    // const d = await JSON.parse(localStorage.getItem("xArray"));
    // const e = await JSON.parse(localStorage.getItem("ogArray"));
    // const theID = await JSON.parse(localStorage.getItem("chainId"));
    // const theStorage = await localStorage.getItem("flow");
    // await localStorage.setItem("flow", JSON.stringify(theStorage));
    // console.log(d)
    // var f = await getXSumoClaimInfo(d);
    // var g = await getOgSumoClaimInfo(e);
    // if(f.length!=0){
    //   console.log(f, g);
    // }
    // if(g.length!=0){
    //   console.log(f, g);
    // }

  }

  const redeem = async () => {
    await localStorage.setItem("flow", JSON.stringify(3));
    setFlow(3);
    let availableAmount = await getAvailableAirdropAmount();
    let withdrawalAmount = await getWithdrawalAmountAirdrop();
    setTotalClaimed(availableAmount);
    setAirdrop(withdrawalAmount);

  }
  const switchToIOTEX = async () => {
    const { ethers } = require("ethers");
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: ethers.utils.hexlify(4689) }]
      });
      await localStorage.setItem("flow", JSON.stringify(flow + 1));
      window.location.reload();
    } catch (err) {
      // This error code indicates that the chain has not been added to MetaMask
      if (err.code === 4902) {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainName: 'IOTEX Mainnet',
              chainId: ethers.utils.hexlify(4689),
              nativeCurrency: { name: 'IOTEX', decimals: 18, symbol: 'IOTEX' },
              rpcUrls: ['https://babel-api.mainnet.iotex.io']
            }
          ]
        });
        await localStorage.setItem("flow", JSON.stringify(flow + 1));
        window.location.reload();
        setFlow(1);
      }
    }
  }
  const reclaimJourney = async () => {
    await localStorage.setItem("flow", JSON.stringify(0));
    setFlow(0);
  }
  const claimSMTX = async () => {
    // setLoading(true)
    const theID = await JSON.parse(localStorage.getItem("chainId"));
    const address = await JSON.parse(localStorage.getItem("address"));
    // var f = await getXSumoClaimInfo(d);
    // var g = await getOgSumoClaimInfo(e);
    // console.log(f, g);
    // console.log(a, b, c, d, e,theID)
    const theWei = (String(Math.floor(parseInt(unclaimOG) + parseInt(unclaimX)) + '0'.repeat(18)));
    // console.log(parseInt(unclaimOG) + parseInt(unclaimX));
    let txn = await claimSMTXAirdropToken(address, theWei, unclaimOgList, [...unclaimGigaList, ...unclaimXList])

    if (txn === 1) {
      setLoading(false);
      // setPurchaseStatus(1);
      localStorage.setItem("flow", 0);
      setMessage("Request submitted, check at your wallet account.");
    }
    localStorage.setItem("flow", 0);
  }
  const switchToClaim = async () => {
    setFlow(4);
    localStorage.setItem("flow", 0);
    let theClaimedSMTX = await getAvailableAirdropAmount();
    setClaimedAmountFlow4(theClaimedSMTX)
    let withdrawalAmountAirdrop = await getWithdrawalAmountAirdrop();
    let withdrawalAmountAirdropNotVested = await getWithdrawalAmountAirdropNotVested();
    setUnlockedAmountFlow4(withdrawalAmountAirdrop)

  }
  const withdrawAvailableSMTX = () => {
    setLoading(true)
    if (Math.floor(unlockedAmountFlow4) != 0) {
      let txn = transferTokenAfterUnlockAirdrop((String(Math.floor(unlockedAmountFlow4)) + '0'.repeat(18)));
      if (txn === 1) {
        setLoading(false);
        setPurchaseStatus(1);
        setMessage("Request submitted, check at your wallet account.");
      }
    } else {
      setLoading(false)
    }


  }
  return (
    <>
      <ClaimAirdropModalStyleWrapper className="modal_overlay">
        <div className="mint_modal_box">
          {chainId != 4689 && flow == 0 ?
            <div className="mint_modal_content">
              <div className="modal_header">
                <h2>Switch your network to Iotex</h2>
                <button onClick={() => claimAirdropModalHandle()}>
                  <FiX />
                </button>
              </div>
              <div className="modal_body text-center">
                <div className="modal_mint_btn m-4">
                  <Button lg variant="mint" onClick={() => switchToIOTEX()}>
                    Start claiming airdrop
                  </Button>
                </div>
              </div>
              <div className="modal_body text-center">
                <div className="modal_mint_btn m-4">
                  <Button lg variant="grey" onClick={() => switchToClaim()}>
                    View Airdrop SMTX
                  </Button>
                </div>
              </div>
              <div className="modal_body text-center">
                <div className="modal_mint_btn m-4">
                  <span className="text-white">If you have a physical wallet, please talk to an admin.</span>
                  <br /><br />
                  <span className="text-white text-center">Token will be unlocked at latest 1st of April 2022  (Potentially Earlier), but rest assured that it has been claimed to your wallet address, you may refer to the contract address</span>
                </div>
              </div>
            </div> : flow == 1 ?
              <div className="mint_modal_content">
                <div className="modal_header">
                  <h2>Claim your Airdrop SMTX here</h2>
                  <button onClick={() => claimAirdropModalHandle()}>
                    <FiX />
                  </button>
                </div>
                {!loading ? <div className="modal_body text-center">
                  {/* <div className="mint_img">
                <img src={mintImg} alt="sumotex nft mint" />
              </div> */}
                  <div className="mint_count_list">
                    <ul>
                      {parseFloat(gigaSumoAirdrop) > 0 ? <li>
                        <h5>Giga SUMO</h5>
                        <h5>
                          {loadingClaim ? "Loading..." : parseFloat(gigaSumoAirdrop) > 0 ? (gigaSumoAirdrop) + " SMTX" : "Nothing available"}
                        </h5>
                      </li> : null}
                      {parseFloat(xsumoAirdrop) > 0 ? <li>
                        <h5>XSUMO</h5>
                        <h5>
                          {loadingClaim ? "Loading..." : parseFloat(xsumoAirdrop) > 0 ? (xsumoAirdrop) + " SMTX" : "Nothing available"}
                        </h5>
                      </li> : null}
                      {parseFloat(ogsumoAirdrop) > 0 ? <li>
                        <h5>OG SUMO</h5>
                        <h5>
                          {loadingClaim ? "Loading..." : parseFloat(ogsumoAirdrop) > 0 ? (ogsumoAirdrop) + " SMTX" : "Nothing available"}
                        </h5>
                      </li> : null}
                      <li>
                        <h5>Total SMTX available to claim</h5>
                        <h5>
                          {loadingClaim ? "Loading..." : (ogsumoAirdrop + xsumoAirdrop + gigaSumoAirdrop) + " SMTX"}
                        </h5>
                      </li>
                    </ul>

                  </div>
                  <div>
                  </div>
                  <p>{message}</p>
                  {loadingClaim ? "Loading" : <div className="modal_mint_btn m-4" style={{ paddingTop: 5 }}>
                    <Button lg variant="mint" onClick={() => checkClaims()}>
                      {loading ? <ReactLoading type={"spin"} color={"black"} /> : null} Check claimable amount
                    </Button>
                  </div>}
                  <div className="modal_mint_btn m-4" style={{ paddingTop: 5 }}>
                    <Button lg variant="grey" onClick={() => reclaimJourney()}>
                      Start again
                    </Button>
                  </div>
                </div> : <p style={{ textAlign: 'center' }}>{message}</p>}
                {/* <span className="text-white text-center">IF your NFT is on a hardware wallet, please claim SMTX token on another wallet as the hardware wallet will have a different address comapre to the ETH network</span>
                <br /> */}
                {loadingClaim ? "" : <span className="text-white text-center">Token will be unlocked latest by 1st of April 2022(Potentially Earlier), but rest it has tied to your wallet, you may refer to the contract address here</span>}
                <div className="modal_bottom_shape_wrap">
                </div>
              </div> : flow == 2 ? <div className="mint_modal_content">
                <div className="modal_header">
                  {loadingUnClaim ? <h2>Checking Unclaim Token</h2> : <h2>Check unclaimed SMTX</h2>}
                  <button onClick={() => claimAirdropModalHandle()}>
                    <FiX />
                  </button>
                </div>
                <div className="mint_count_list">
                  {loadingUnClaim ? <div className="p-4">
                    <div className="">
                      <RotatingSquare
                        height="150"
                        width="150"
                        radius="9"
                        color="#70A591"
                        ariaLabel="loading"
                        wrapperStyle
                        wrapperClass
                      />
                    </div>
                    <div className="modal_header">
                      <h2 className="text-center text-white">
                        Loading</h2>

                    </div>
                    <div className="modal_mint_btn m-4" style={{ paddingTop: 5 }}>
                    </div>
                  </div> : <ul>
                    <li>
                      <h5>XSUMO</h5>
                      <h5>
                        {(unclaimX) + " SMTX"}
                      </h5>
                    </li>
                    <li>
                      <h5>OG SUMO</h5>
                      <h5>
                        {(unclaimOG) + " SMTX"}
                      </h5>
                    </li>
                    <li>
                      <h5>Unclaimed SMTX</h5>
                      <h5>
                        {(parseInt(unclaimOG) + parseInt(unclaimX)) > 0 ? parseInt(unclaimOG) + parseInt(unclaimX) + " SMTX" : "0 SMTX"}
                      </h5>
                    </li>
                    <li>
                      <h5>Claimed SMTX</h5>
                      <h5>
                        {(totalClaimed) + " SMTX"}
                      </h5>
                    </li>
                  </ul>}
                </div>
                <div className="modal_mint_btn m-4" style={{ paddingTop: 5 }}>
                  {parseInt(unclaimOG) > 0 || parseInt(unclaimX) > 0 ? <Button lg variant="mint" onClick={() => redeem()}>
                    Redeem SMTX
                  </Button> : null}
                </div>
                <div className="modal_body text-center">
                  <div className="modal_mint_btn m-4">
                    <Button lg variant="grey" onClick={() => switchToClaim()}>
                      View Claimed SMTX
                    </Button>
                  </div>
                </div>
                <div className="modal_mint_btn" style={{ paddingTop: 5 }}>
                  <Button lg variant="grey" onClick={() => reclaimJourney()}>
                    Start again
                  </Button>
                </div>
              </div> : flow == 3 ? <div className="mint_modal_content">
                <div className="modal_header">
                  <h2>Claim your Airdrop SMTX here</h2>
                  <button onClick={() => claimAirdropModalHandle()}>
                    <FiX />
                  </button>
                </div>
                {<div className="modal_body text-center">
                  <div className="mint_count_list">
                    <ul>
                      <li>
                        <h5>SMTX available to claim</h5>
                        <h5>
                          {(parseInt(unclaimOG) + parseInt(unclaimX)) > 0 ? parseInt(unclaimOG) + parseInt(unclaimX) + " SMTX" : "0 SMTX"}
                        </h5>
                      </li>
                      <li>
                        <h5>Claimed SMTX</h5>
                        <h5>
                          {(totalClaimed) + " SMTX"}
                        </h5>
                      </li>
                      <li>
                        <h5>Total unlocked SMTX</h5>
                        <h5>
                          {(parseFloat(airdropAmount)) + " SMTX"}
                        </h5>
                      </li>
                      {/* <li>
                        <h5>Total Claimed SMTX Amount</h5>
                        <h5>
                          {loadingClaim ? "Loading..." : 0 + " SMTX"}
                        </h5>
                      </li> */}
                    </ul>
                    <ul>
                      {/* <li>
                    <h5>Total Unclaim Amount</h5>
                    <h5>
                      <span>{loadingClaim ? "Loading..." : (totalAmount)}</span>
                    </h5>
                  </li> */}
                    </ul>
                  </div>
                  <div>
                  </div>
                  <p>{message}</p>

                  <div className="modal_mint_btn m-4" style={{ paddingTop: 5 }}>
                    <Button lg variant="mint" onClick={() => claimSMTX()}>
                      {loading ? <ReactLoading type={"spin"} color={"white"} /> : null} Claim
                    </Button>
                  </div>
                  <div className="modal_mint_btn m-4" style={{ paddingTop: 5 }}>
                    <Button lg variant="grey" onClick={() => reclaimJourney()}>
                      {loading ? <ReactLoading type={"spin"} color={"white"} /> : null} Start claiming airdrop
                    </Button>
                  </div>
                  <div className="modal_mint_btn m-4" style={{ paddingTop: 5 }}>
                    <Button lg variant="grey" onClick={() => reclaimJourney()}>
                      Start again
                    </Button>
                  </div>
                  {/* <div className="modal_mint_btn m-4" style={{ paddingTop: 5 }}>
                    {purchaseStatus == 0 ? <Button lg variant="grey" onClick={() => claimSMTXDifferentAdd()}>
                      {loading ? <ReactLoading type={"spin"} color={"black"} /> : null} Claim SMTX on a different address
                    </Button> : <p>Claim requested, please refer to your wallet for the transaction to be completed</p>}
                  </div> */}
                </div>}
                <span className="text-white text-center text-overflow">Airdrop Contract Address: <br />0x91DB8987ebA258c6Cf6bD7D86CAd40FeBDBf9A3b </span>
                <br />
                {/* <span className="text-white text-center">IF your NFT is on a hardware wallet, please claim SMTX token on another wallet as the hardware wallet will have a different address comapre to the ETH network</span> */}
                <br />
                <span className="text-white text-center">Token will be unlocked at latest 1st of April 2022  (Potentially Earlier), but rest assured that it has been claimed to your wallet address, you may refer to the contract address</span>
                <div className="modal_bottom_shape_wrap">
                </div>
              </div> : <div className="mint_modal_content">
                <div className="modal_header">
                  {loading ? <h2>Loading</h2> : <h2>Claimed SMTX</h2>}
                  <button onClick={() => claimAirdropModalHandle()}>
                    <FiX />
                  </button>
                </div>
                {<div className="modal_body text-center">
                  <div className="mint_count_list">
                    <ul>
                      <li>
                        <h5>Claimed SMTX</h5>
                        <h5>
                          {(theClaimedAmountFlow4) + " SMTX"}
                        </h5>
                      </li>
                      <li>
                        <h5>Total unlocked SMTX</h5>
                        <h5>
                          {Math.floor(parseFloat(unlockedAmountFlow4).toFixed(4)) + " SMTX"}
                        </h5>
                      </li>
                    </ul>
                  </div>
                  <div>
                  </div>
                  <p>{message}</p>
                  <div className="modal_mint_btn m-4" style={{ paddingTop: 5 }}>
                    <Button lg variant="mint" onClick={() => withdrawAvailableSMTX()}>
                      {loading ? <ReactLoading type={"spin"} color={"white"} /> : null} Withdraw available SMTX
                    </Button>
                  </div>
                  <div className="modal_mint_btn m-4" style={{ paddingTop: 5 }}>
                    <Button lg variant="grey" onClick={() => reclaimJourney()}>
                      Start again
                    </Button>
                  </div>
                </div>}

                <span className="text-white text-sm text-center text-overflow">SMTX Contract Address: <br />0x102203517ce35AC5Cab9a2cda80DF03f26c7419b </span>
                <span className="text-white text-sm text-center text-overflow">Airdrop Contract Address: <br />0x91DB8987ebA258c6Cf6bD7D86CAd40FeBDBf9A3b </span>
                <br />
                {/* <span className="text-white text-center">IF your NFT is on a hardware wallet, please claim SMTX token on another wallet as the hardware wallet will have a different address comapre to the ETH network</span> */}
                <br />
                <span className="text-white text-center">Token will be unlocked at latest 1st of April 2022  (Potentially Earlier), but rest assured that it has been claimed to your wallet address, you may refer to the contract address</span>
                <div className="modal_bottom_shape_wrap">
                </div>
              </div>}
        </div>
      </ClaimAirdropModalStyleWrapper>
    </>
  );
};

export default ClaimAirdropModal;
