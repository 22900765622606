import characterThumb1 from "../../images/news/crypto_news.jpeg";
import characterThumb2 from "../../images/news/crypto_slate.png";
import characterThumb3 from "../../images/news/economic_times.png";
import characterThumb4 from "../../images/news/coingecko.png";
import characterThumb5 from "../../images/news/daily_coin.png";

const data = [

  {
    thumb: characterThumb1,
    link:"https://cryptonews.com/news/sumotex-presale-goes-live-first-protocol-spearhead-usd-250-mil-tvl-tokenisation-worth-of-real-estate-upon-ido.htm"
  },
  {
    thumb: characterThumb2,
    link:"https://cryptoslate.com/press-releases/sumotex-presale-goes-live-first-protocol-to-spearhead-usd-250-mil-tvl-tokenisation-worth-of-real-estate-upon-ido/"
  },
  {
    thumb: characterThumb3,
    link:"https://economictimes.indiatimes.com/industry/banking/finance/10-best-altcoins-to-invest-in-2022-before-it-goes-to-the-moon/articleshow/90939865.cms"
  },
  {
    thumb: characterThumb4,
    link:"https://www.coingecko.com/en/coins/sumotex"
  },
  {
    thumb: characterThumb5,
    link:"https://dailycoin.com/sumotex-presale-goes-live-first-protocol-to-spearhead-usd-250-mil-tvl-tokenisation-worth-of-real-estate-upon-ido/"
  },
];

export default data;
