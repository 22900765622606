const data = [
  {
    title: "What is the AssetFi Protocol and SMTX?",
    text: "AssetFi Protocol- the main protocol which is used to tokenise any off-chain real estate from real estates, bonds and company shares. SMTX - is considered as a utility token which are used within the AssetFi protocol. Holders/investors can trade, swap within the protocol between its real world lending and investment funds. Find more information in our whitepaper about SMTX:",
  },
  {
    title: "How do I buy SMTX?",
    text: "To buy a SMTX you’ll need the following: A MetaMask wallet/extension,Select Ethereum network, Prepare  sufficient USDC to purchase desired SMTX amount.",
  },
  {
    title: "Where I can see my SMTX?",
    text: "Once you go through the transaction process, you’ll be able to see your SMTX in your wallet when the protocol is released and vesting period is unlocked.",
  },
  {
    title: "How much time do I have to wait for the transaction?",
    text: "Once you have selected your SMTX and before the final check out, you’ll need to specify a gas fee, a transaction fee that you need to pay when interacting with the blockchain. You’ll be able to select an amount that should be acceptable to your waiting time, with lower fees corresponding with a longer wait. It should only take minutes to complete the transaction even at lower rates.",
  },
  {
    title: "How much does a SMTX cost?",
    text: "Prices will be fixed in  0.07 USD on the website until all allocated SMTX are sold out. The price for the SMTX Launch sale will be $0.10 USD.",
  }
];

export default data;
