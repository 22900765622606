import { FaLinkedinIn, FaTwitter, FaInstagram } from "react-icons/fa";


import avatarOne from "../../images/team/dexter.jpeg";
import avatarTwo from "../../images/team/3.png";
import avatarThree from "../../images/team/4.png";
import avatarFour from "../../images/team/2.png";
import avatarFive from "../../images/team/mike.png";
import avatarThumb2 from "../../images/team/crew_avatar2.png";
import avatarThumb3 from "../../images/team/2.jpeg";
import avatarThumb4 from "../../images/team/3.jpeg";
import avatarThumb5 from "../../images/team/4.jpeg";

const data = [
  {
    avatar: avatarOne,
    name: "Dexter Leow",
    designation: "Co-Founder/CEO",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/dexter-leow-317820a9/",
      }
    ],
  },
  {
    avatar: avatarTwo,
    name: "Mike Leow",
    designation: "Co-Founder/CTO",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "#",
      }
    ],
  },
  {
    avatar: avatarThree,
    name: "Dato’ Mustafha Abd Razak",
    designation: "Chairman",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/dato-mustafha-hj-abd-razak-7338a82/",
      }
    ],
  },
  {
    avatar: avatarFour,
    name: "Mohd Nasrul Bin Khairi",
    designation: "Finance Director",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/mohd-nasrul-khairi-al-marbawi-aba40147/",
      }
    ],
  },
  {
    avatar: avatarFive,
    name: "Sahidi Bin Mohamad",
    designation: "Chief Legal Officer",
    socialLinks: [
      {
        icon: <FaLinkedinIn />,
        url: "https://www.linkedin.com/in/sahidi-mohamad-0aa69b50/",
      }
    ],
  },
];

export default data;
