import { useModal } from "../../../utils/ModalContext";
import Button from "../../../common/button";
import ClaimWrapper from "./Claim.style";
import { isMetaMaskInstalled } from '../../../config';

const Claim = () => {
    const { claimModalHandle,walletModalHandle,claimAirdropModalHandle, metamaskModalHandle, account } = useModal();
    const handleWalletConnect = async () =>{
        if(!isMetaMaskInstalled()){
          metamaskModalHandle();
        }else{
          walletModalHandle();
        }
      }
    return (
        <ClaimWrapper id="Claim">
            <div style={{ display: "flex", flexDirection: 'column',alignItems:'center', justifyContents: "center", textAlign: 'center' }}>
                <p style={{display:"flex",alignSelf:'center', textAlign: 'center', fontSize: 20, fontWeight: 'bold' }}>Claim your purchased token here </p>
                {
                    account ?
                        <Button lg variant="mint"
                            style={{ fontSize: 30 }}
                            onClick={() => claimModalHandle()}>
                            {" "}
                            CLAIM
                        </Button> :
                        <Button lg variant="dark" onClick={() => handleWalletConnect()}>
                            {" "}
                            Connect now
                        </Button>
                }
                     {
                    account ?
                    <div className="m-2">
                        <Button lg variant="dark"
                            style={{ fontSize: 30 }}
                            onClick={() => claimAirdropModalHandle()}>
                            {" "}
                            CLAIM Airdrop
                        </Button></div> :
                       ""
                }
            </div>

        </ClaimWrapper>
    );
};

export default Claim;
