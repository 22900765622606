import aboutCardIcon1 from "../../images/icon/aboutus_card_icon1.svg"
import aboutCardIcon2 from "../../images/icon/aboutus_card_icon2.svg"
import aboutCardIcon3 from "../../images/icon/aboutus_card_icon3.svg"

 const data = {
    aboutDescription1: 'Founded in 2021, Sumotex was established to revolutionize the long stagnated real estate industry. Traditional (Web2) Real Estates are known to be illiquid, expensive, and vague where we think that it is time for web3 Real Estate to take over. Institutional finance has always been the foundation of our economic growth, a paradigm shift towards blockchain is unavoidable. SUMOTEX always believe that the stagnated institutional finance and real estate sector is poised to be disrupted with our Web3 solution, the AssetFi protocol. Our protocol is based on the tokenization of off-chain assets ( Real estates, bonds, funds, company shares). ',
    aboutDescription2: '',
    aboutInfo: [
        {
            icon: aboutCardIcon1,
            title: "Decentralization of Asset Manager (Role)",
            text: "With our AssetFi protocol, any asset owner, family offices and financial institutions can gain access to the technology and resources SMTX provide on a deal by deal or portfolio basis."
        },
        {
            icon: aboutCardIcon2,
            title: "Investment and lending vault (Asset)",
            text: 'Onboarded asset manager will be able to issue/start their funds with their offering after the asset is approved by the governance protocol.'
        },
        {
            icon: aboutCardIcon3,
            title: "Real Estate backed STABLECOIN",
            text: 'SMTX will issue its first ever real estate backed stable coin, assets verified by a  public trustee which acts as a node validator. It will be the world most safe stable coin as it is pegged against real estate, one of the most capital secured assets.'
        }
    ]
}

export default data;