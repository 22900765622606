import checkIcon from "../../images/icon/roadmap_check.svg"
import spinnerIcon from "../../images/icon/roadmap_spiner.png"

const data = [
    {
        icon: spinnerIcon,
        title: "Phase 01 ",
        subtitle: "Seed Launch/Presale/ICO",
        textArray: ["- Tokenisation of 2.5MIL USD pilot project"]
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 02",
        subtitle: "Achieved TVL of 500 Million",
        textArray: ["- Launch of USD 250mil tokenisation mixed development project.", "- First bond/unit trust tokenisation.", "- First ever physical restaurant tokenisation project"]
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 03",
        subtitle: "Issuance of first real estate backed STABLECOIN",
        textArray: ["- Launch of Real estate earning funds (Tokenise to earn model)"]
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 04",
        subtitle: "1 billion USD In TVL",
        textArray: ["- Onboarding of buldge bracket bank ","- Launch of NFT insurance scheme and banking (lending/financing).","- Onboarding of buldge bracket bank.","- Inter-Blockchain Communication Protocol (IBC) integration","- 1 billion USD In TVL"]
    }, 
    {
        icon: spinnerIcon,
        title: "Phase 05",
        subtitle: "Mainnet",    
        textArray: ["- 20 Validators","- Mainnet scaling."]
    }
]

export default data;