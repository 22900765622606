import { useState } from "react";
import { useModal } from "../../../utils/ModalContext";
import { FiX } from "react-icons/fi";
import Button from "../../button";
import MintModalStyleWrapper from "./MintNow.style";
import ReactLoading from 'react-loading';
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";
import { ethers } from 'ethers';
import { getPurchasedAmount,getApprovalForUSDC, purchaseCoinWithETH, purchaseCoin, getChainLinkETHUSDPrice, approved } from '../../../utils/web3mint';
import { useEffect } from "react";

const MintNowModal = () => {
  const [count, setCount] = useState(1000);
  const [purchaseType, setPurchaseType] = useState(0);
  const [message, setMessage] = useState('');
  const [remaining, setRemaining] = useState('Loading');
  const [purchaseStatus, setPurchaseStatus] = useState(0);
  const [ethPrice, setETHPrice] = useState(0);
  const [displayETHPrice, setDisplayETHPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const { mintModalHandle } = useModal();

  let price = 0.07;
  useEffect(() => {
    const calculateETHPrice = async () => {
      let approvalAmount= await getApprovalForUSDC();
      if(approvalAmount>0){
        setPurchaseStatus(1)
        setCount(Math.ceil(approvalAmount/0.07))
      }else{
        setCount(1000)
      }
      let ethPrice = await getChainLinkETHUSDPrice();
      setETHPrice(ethers.utils.formatUnits(ethPrice,0))
      let displayPricing = ethers.utils.formatUnits(ethPrice, 8)
      setDisplayETHPrice(displayPricing);

    }
    calculateETHPrice();
  }, [])
  const onChangeCount = (val) => {
    setCount(val)
  }


  const approveTxn = async () => {
    setLoading(true)
    let txn = await approved(Math.floor(count * price))
    if (txn === 1) {
      setLoading(false)
      setPurchaseStatus(1);
    }
  }
  const purchaseNow = async () => {
    if (count >= 100000000) {
      setMessage('Maximum purchase amount exceeding!');
    } else if (count < 1) {
      setMessage('Minimum purchase amount 100');
    } else {
      setLoading(true);
      let txn2 = await purchaseCoin(String(count) + '0'.repeat(18), String(Math.floor(count * price)) + '0'.repeat(6));
      if (txn2 === 1) {
        setLoading(false)
        setPurchaseStatus(2);
        setMessage('Purchase successfully!');
      }
    }
  }
  const purchaseWithETH = async () => {
    if (count >= 100000000) {
      setMessage('Maximum purchase amount exceeding!');
    } else if (count < 1) {
      setMessage('Minimum purchase amount 100');
    } else {
      setLoading(true);
      let thePrice = (Math.floor(count * price) / displayETHPrice ) *1000000000000000000
      // console.log(thePrice,thePrice.toFixed(5))
      let txn2 = await purchaseCoinWithETH(String(count) + '0'.repeat(18),String(thePrice));
      if (txn2 === 1) {
        setLoading(false)
        setPurchaseStatus(2);
        setMessage('Purchase successfully!');
      }
    }

  }
  const changeType = async (selectedType) => {
    setPurchaseType(selectedType);

  }
  useEffect(() => {
    const calculateRemainingItems = async () => {
      let totalPurchasedAmount = await getPurchasedAmount();
      setRemaining(totalPurchasedAmount);
    }
    const calculateETHPrice = async () => {
        let ethPrice = await getChainLinkETHUSDPrice();
        //setMessage((ethPrice));
        setETHPrice(ethers.utils.formatUnits(ethPrice,0))
        let displayPricing = ethers.utils.formatUnits(ethPrice, 8)
        setDisplayETHPrice(displayPricing);
    }
    calculateETHPrice();
    calculateRemainingItems();
  }, []);

  return (
    <>
      <MintModalStyleWrapper className="modal_overlay">
        <div className="mint_modal_box">
          <div className="mint_modal_content">
            <div className="modal_header">
              <h2>Purchase your SMTX here</h2>
              <button onClick={() => mintModalHandle()}>
                <FiX />
              </button>
            </div>
            {purchaseType == 0 ?
              <div className="modal_body text-center">
                <div className="modal_mint_btn" style={{ marginBottom: 10 }}>
                  <Button lg variant="mint" onClick={() => changeType(2)}>
                    {loading ? <ReactLoading type={"spin"} color={"black"} /> : null}Purchase with ETH
                  </Button>
                </div>
                <div className="modal_mint_btn">
                  <Button lg variant="grey" onClick={() => changeType(1)}>
                    {loading ? <ReactLoading type={"spin"} color={"black"} /> : null}Purchase with USDC
                  </Button>
                </div>
                <div style={{ color: 'white', marginTop: 10 }}>
                    <span></span><br />
                    <span>Increase Gas Fee for faster transaction</span><br />
                  
                  </div>
              </div> : purchaseType == 1 ?
                <div className="modal_body text-center">
                  {/* <div className="mint_img">
                <img src={mintImg} alt="sumotex nft mint" />
              </div> */}
                  <div className="mint_count_list">
                    <ul>
                      <li>
                        <h5>Remaining</h5>
                        <h5>
                          {5000000 - remaining}/<span>5,000,000</span>
                        </h5>
                      </li>
                      <li>
                        <h5>Pre-sale Price</h5>
                        <h5>{price} USD</h5>
                      </li>
                      <li>
                        <h5>Quantity: </h5>
                        <div className="mint_quantity_sect">
                          <input
                            type="text"
                            id="quantity"
                            value={count}
                            onChange={(e) => onChangeCount(Math.floor(e.target.value))}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h1 style={{ color: 'white' }} >
                      <span >{Math.floor(count * price)}</span> USD
                    </h1>
                  </div>
                  {message && <p>{message}</p>}
                  <div className="modal_mint_btn">
                    {purchaseStatus === 0 ? <Button lg variant="black" onClick={() => approveTxn()}>
                      {loading ? <ReactLoading type={"spin"} color={"black"} /> : null}Approve Transactions for USDC
                    </Button> : purchaseStatus == 1 ? <Button lg variant="mint" onClick={() => purchaseNow()}>
                      {loading ? <ReactLoading type={"spin"} color={"black"} /> : null}Purchase Now
                    </Button> : ""}
                  </div>
                  <div style={{ color: 'white', marginTop: 10 }}>
                    <span></span><br />
                    <span>Two steps process</span><br />
                    <span>1. Approve transaction for USDC</span><br />
                    <span>2. Upon completion of approval, click purchase for SMTX</span>
                    <span>Increase Gas Fee for faster transaction</span><br />
                  </div>
                </div> : <div className="modal_body text-center">
                  {/* <div className="mint_img">
                <img src={mintImg} alt="sumotex nft mint" />
              </div> */}
                  <div className="mint_count_list">
                    <ul>
                      <li>
                        <h5>Remaining</h5>
                        <h5>
                          {5000000 - remaining}/<span>5,000,000</span>
                        </h5>
                      </li>
                      <li>
                        <h5>Pre-sale Price</h5>
                        <h5>{price} USD</h5>
                      </li>
                      <li>
                        <h5>ETH Current Price</h5>
                        <h5>{parseFloat(displayETHPrice).toFixed(3)} USD</h5>
                      </li>
                      <li>
                        <h5>Quantity: </h5>
                        <div className="mint_quantity_sect">
                          <input
                            type="text"
                            id="quantity"
                            value={count}
                            onChange={(e) => onChangeCount(Math.floor(e.target.value))}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h1 style={{ color: 'white',fontSize:22 }} >
                      <span >{Math.floor(count * price) / displayETHPrice}</span> ETH
                    </h1>
                  </div>
                 <p>{message}</p>
                  <div className="modal_mint_btn" style={{ marginTop: 10 }}> <Button lg variant="mint" onClick={() => purchaseWithETH()}>
                    {loading ? <ReactLoading type={"spin"} color={"black"} /> : null}Purchase with ETH
                  </Button>
                  </div>
                  <div style={{ color: 'white', marginTop: 10 }}>
                    <span></span><br />
                    <span>Increase Gas Fee for faster transaction</span><br />
                  </div>
                </div>}
            <div className="modal_bottom_shape_wrap">
              <span className="modal_bottom_shape shape_left">
                <img src={hoverShape} alt="sumotex nft hover shape" />
              </span>
              <span className="modal_bottom_shape shape_right">
                <img src={hoverShape} alt="sumotex nft hover shape" />
              </span>
            </div>
          </div>
        </div>
      </MintModalStyleWrapper>
    </>
  );
};

export default MintNowModal;
