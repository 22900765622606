import { useState } from "react";
import { useModal } from "../../../utils/ModalContext";
import { FiX } from "react-icons/fi";
import Button from "../../button";
import ClaimModalStyleWrapper from "./ClaimNow.style";
import { isMetaMaskInstalled } from '../../../config';
import ReactLoading from 'react-loading';
import hoverShape from "../../../assets/images/icon/hov_shape_L.svg";
import { ethers } from 'ethers';
import { getWithdrawalAmountUSDC, getWithdrawalAmountETH, transferTokenAfterUnlockETH, transferTokenAfterUnlockUSDC, approved, getAvailableAmount } from '../../../utils/web3mint';
import { useEffect } from "react";
import { getChainId,ethereum } from "../../../config";

const ClaimNowModal = () => {
  const [message, setMessage] = useState('Loading...');
  const [retry, setRetry] = useState(1);
  const [chainId, setChainId] = useState(0);
  const [remainingUSDC, setRemainingUSDC] = useState('Loading...');
  const [remainingETH, setRemainingETH] = useState("");
  const [totalAmount, setTotalAmount] = useState('Loading...');
  const [purchaseStatus, setPurchaseStatus] = useState(0);
  const [usdcWei, setUSDCWei] = useState(0);
  const [ethWei, setETHWei] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingClaim, setLoadingClaim] = useState(true);
  const { claimModalHandle } = useModal();

  const withdrawSMTXUSDC = async () => {
    setLoading(true)
    let txn = transferTokenAfterUnlockUSDC(usdcWei)
    if (txn === 1) {
      setLoading(false);
      setPurchaseStatus(1);
      setMessage("Request submitted, check at your wallet account.");
    }


  }
  const withdrawSMTXETH = async () => {
    setLoading(true)
    let txn = transferTokenAfterUnlockETH(ethWei)
    if (txn === 1) {
      setLoading(false);
      setPurchaseStatus(1);
      setMessage("Request submitted, check at your wallet account.");
    }

  }

  const getTokens = async () => {
    var chain = await getChainId();
    let availableAmount = await getAvailableAmount();
    let withdrawalAmountUSDC = await getWithdrawalAmountUSDC();
    let withdrawalAmountETH = await getWithdrawalAmountETH();
    setUSDCWei(String(Math.floor(withdrawalAmountUSDC) + '0'.repeat(18)));
    setETHWei(String(Math.floor(withdrawalAmountETH) + '0'.repeat(18)));
    // console.log(Math.floor(withdrawalAmount)+'0'.repeat(18));
    setRemainingUSDC(withdrawalAmountUSDC);
    setRemainingETH(withdrawalAmountETH);
    setTotalAmount(availableAmount);
    setChainId(chain);
    setLoadingClaim(false);
    if (chain == 1) {
      setMessage("")
    } else {
      setMessage("Looks like you are not on Ethereum network, please switch over")
    }

  }
  useEffect(() => {
    getTokens();
  }, []);

  return (
    <>
      <ClaimModalStyleWrapper className="modal_overlay">
        <div className="mint_modal_box">
          <div className="mint_modal_content">
            <div className="modal_header">
              <h2>Claim your SMTX here</h2>
              <button onClick={() => claimModalHandle()}>
                <FiX />
              </button>
            </div>
            {!loading ? <div className="modal_body text-center">
              {/* <div className="mint_img">
                <img src={mintImg} alt="sumotex nft mint" />
              </div> */}
              <div className="mint_count_list">
                <ul>
                  <li>
                    <h5>USDC - Available to claim</h5>
                    <h5>
                      {loadingClaim ? "Loading..." : parseFloat(remainingUSDC)>0?Math.floor(parseFloat(remainingUSDC).toFixed(4)):"Nothing available"}
                    </h5>
                  </li>
                </ul>
                <ul>
                  <li>
                    <h5>ETH - Available to claim</h5>
                    <h5>
                      {loadingClaim ? "Loading..." : parseFloat(remainingETH)>0?Math.floor(parseFloat(remainingETH).toFixed(4)):"Nothing available"}
                    </h5>
                  </li>
                </ul>
                <ul>
                  <li>
                    <h5>Total Unclaim Amount</h5>
                    <h5>
                      <span>{loadingClaim ? "Loading..." : (totalAmount)}</span>
                    </h5>
                  </li>
                </ul>
              </div>
              <div>
              </div>
              <p>{message}</p>
              <div className="modal_mint_btn">
                {purchaseStatus == 0 ? <Button lg variant="mint" onClick={() => withdrawSMTXUSDC()}>
                  {loading ? <ReactLoading type={"spin"} color={"black"} /> : null} Claim USDC
                </Button> : ""}
              </div>
              <div className="modal_mint_btn" style={{ paddingTop: 5 }}>
                {purchaseStatus == 0 ? <Button lg variant="grey" onClick={() => withdrawSMTXETH()}>
                  {loading ? <ReactLoading type={"spin"} color={"black"} /> : null} Claim ETH
                </Button> : <p>Claim requested, please refer to your wallet for the transaction to be completed</p>}
              </div>
            </div> : <p style={{ textAlign: 'center' }}>{message}</p>}

            <div className="modal_bottom_shape_wrap">
              <span className="modal_bottom_shape shape_left">
                <img src={hoverShape} alt="sumotex nft hover shape" />
              </span>
              <span className="modal_bottom_shape shape_right">
                <img src={hoverShape} alt="sumotex nft hover shape" />
              </span>
            </div>
          </div>
        </div>
      </ClaimModalStyleWrapper>
    </>
  );
};

export default ClaimNowModal;
